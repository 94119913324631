<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<{
  /**
   * Value of the progress
   *
   * eg: 80/100
   */
  value: number
  /**
   * Size of Circle
   *
   * eg: 32 | 40 | 64
   */
  radius?: number
  /**
   * Thickness of lines
   *
   * eg: 5 | 11 | 16
   */
  stroke?: number
}>(), {
  radius: 40,
  stroke: 5,
})

const circleRadius = computed(() => props.radius - (props.stroke * 2))
const circumference = computed(() => circleRadius.value * 2 * Math.PI)
const strokeDashoffset = computed(() => circumference.value - (props.value / 100) * circumference.value)

const strokeTypes = computed(() => {
  if (props.value >= 80)
    return 'stroke-success'
  else if (props.value >= 60)
    return 'stroke-warning'
  else
    return 'stroke-error'
})

const textTypes = computed(() => {
  if (props.value >= 80)
    return 'text-success'
  else if (props.value >= 60)
    return 'text-warning'
  else
    return 'text-error'
})
</script>

<template>
  <div
    class="relative flex items-center justify-center"
  >
    <span
      v-bind="$attrs"
      :class="`absolute font-semibold ${textTypes}`"
    >
      {{ value }}%
    </span>

    <svg
      :class="`absolute ${strokeTypes}`"
      :height="radius * 2"
      :width="radius * 2"
    >
      <circle
        :stroke-dasharray="`${circumference} ${circumference}`"
        :stroke-width="stroke"
        :style="{ strokeDashoffset }"
        fill="transparent"
        :r="circleRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>

    <svg
      stroke="$c-gray-100"
      :height="radius * 2"
      :width="radius * 2"
    >
      <circle
        :r="circleRadius"
        :cx="radius"
        :cy="radius"
        :stroke-width="stroke"
        fill="transparent"
      />
    </svg>
  </div>
</template>

<style scoped>
circle {
  transition: stroke-dashoffset 1.35s;
  transform: rotate(-90deg) scaleY(-1);
  transform-origin: 50% 50%;
}
</style>
