<script setup lang="ts">
import type { MediaVideoPlayer } from '#build/components'

definePageMeta({
  layout: 'playback',
  path: '/p/:uuid',
})

const store = usePlaybackStore()
const { playback } = storeToRefs(store)

useSeoMeta({
  title: playback.value?.applicantName,
  ogTitle: playback.value?.applicantName,
  description: `Interview Playback of ${playback.value?.applicantName} applying for ${playback.value?.jobTitle}`,
  ogDescription: `Interview Playback of ${playback.value?.applicantName} applying for ${playback.value?.jobTitle}`,
  ogImage: playback.value?.thumbnailUrl,
  twitterCard: 'summary_large_image',
})

const videoPlayer = ref<InstanceType<typeof MediaVideoPlayer> | null>(null)
</script>

<template>
  <div class="flex flex-col gap-6">
    <PlaybackHeader
      :applicant-name="playback?.applicantName"
      :date="playback?.updatedAt"
      :job-post-title="playback?.jobTitle"
    />

    <div class="grid grid-cols-1 gap-6 lg:grid-cols-5">
      <div class="col-span-1 flex flex-col lg:col-span-3 space-y-6">
        <MediaVideoPlayer
          ref="videoPlayer"
          :src="playback?.recordingUrl"
          :items="getTranscriptionItems(playback?.interviewAnswers, {
            minimumBandScore: playback?.minimumBandScore,
          })"
          :poster="playback?.thumbnailUrl"
        />
        <TranscriptionItems
          :video-player="videoPlayer"
          :items="getTranscriptionItems(playback?.interviewAnswers, {
            minimumBandScore: playback?.minimumBandScore,
          })"
          :applicant-name="playback?.applicantName"
        />
        <PlaybackResumeDownload
          :resume="playback?.resume"
        />
        <PlaybackScreeningQuestion
          :items="playback?.screeningAnswers"
        />
        <PlaybackRecruitersNote :note="playback?.note" />
      </div>
      <div class="col-span-1 flex flex-col gap-y-6 lg:col-span-2">
        <PlaybackAIPerformanceScore
          :value="playback?.overallScore || getTranscriptionOverallScore(playback?.interviewAnswers)"
        />
        <PlaybackVerbalAssessment
          :items="{
            minimumScore: playback?.minimumBandScore,
            overallScore: playback?.overallBandScore,
            description: playback?.overallBandScoreDescription,
            isPrequalified: playback?.isPrequalified,
          }"
        />
        <PlaybackQualificationScoreItems
          :items="getQualificationScoreAnalytics(playback?.analytics)"
        />
        <PlaybackBehavioralScoreItems
          :items="getBehavioralScoreAnalytics(playback?.analytics)"
        />
        <PlaybackDiscProfileAssessment
          :items="getDiscProfileAssessmentAnalytics(playback?.analytics)"
        />
        <PlaybackBigFivePersonality
          :items="getBigFiveAnalytics(playback?.analytics)"
        />
      </div>
    </div>
  </div>
</template>
