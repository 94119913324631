<script setup lang="ts">
const store = useRoomStore()
const { room } = storeToRefs(store)
</script>

<template>
  <div
    container="padded"
    grid="~ cols-[1fr_1fr_1fr]"
    class="fixed inset-x-0 top-0 z-20 gap-4 bg-gray-100/70 py-2 backdrop-blur-sm md:sticky md:bg-white md:backdrop-blur-none md:dark:bg-gray-950 dark:bg-gray-950/90"
    border="b base"
  >
    <!-- Left -->
    <!-- Header -->
    <div class="h-40px flex items-center">
      <ClientOnly>
        <GAvatar
          v-if="room?.organization?.logo" :src="room?.organization?.logo"
          :label="room?.organization?.name"
          avatar="~"
          :alt="room?.organization?.name"
          rounded="md"
          square="auto"
          class="hidden sm:flex"
          :una="{
            avatarImage: 'object-cover w-auto h-40px aspect-auto',
          }"
        />
      </ClientOnly>
    </div>

    <!-- Center -->
    <div
      col-span="1"
      class="mx-auto flex grow items-center justify-center whitespace-nowrap"
    >
      <GButton
        size="0.95rem"
        :label="`${room?.jobPost?.title}`"
        leading="i-twemoji-briefcase mr-1.5 text-lg"
        btn="~"
      />
    </div>

    <!-- Right -->
    <div
      col-span="1"
      class="ml-auto flex items-center"
    >
      <AppHeaderControls />
    </div>
  </div>
</template>
