<script setup lang="ts">
const props = withDefaults(defineProps<{
  max?: number
  /**
   * Maximum value for scrubber
   */
  secondary?: number
  /**
   * Secondary value mostly used as a buffer
   */
  modelValue: number
  /**
   * Primary value of scrubber
   */
  isMuted?: boolean
  /**
   * Determine to hide/show scrubber
   */
}>(), {
  min: 0,
  max: 100,
  secondary: 0,
  isMuted: false,
})

const emit = defineEmits(['update:modelValue'])

const scrubber = ref()
const scrubbing = ref(false)
const pendingValue = ref(0)

useEventListener('mouseup', () => scrubbing.value = false)

const value = useVModel(props, 'modelValue', emit)
const { elementX, elementWidth } = useMouseInElement(scrubber)

watch([scrubbing, elementX], () => {
  const progress = Math.max(0, Math.min(1, (elementX.value) / elementWidth.value))
  pendingValue.value = progress * props.max
  if (scrubbing.value)
    value.value = pendingValue.value
})
</script>

<template>
  <div class="py-5" @mousedown="scrubbing = true">
    <div
      ref="scrubber"
      v-bind="$attrs"
      class="relative h-1 cursor-pointer select-none rounded bg-black bg-opacity-20 dark:bg-white dark:bg-opacity-10"
    >
      <div class="relative h-full w-full overflow-hidden rounded">
        <div class="absolute left-0 top-0 h-full w-full rounded bg-gray-100/50" :style="{ transform: `translateX(${secondary / max * 100 - 100}%)` }" />
        <div v-if="!isMuted" class="relative h-full w-full rounded bg-primary-500" :style="{ transform: `translateX(${value / max * 100 - 100}%)` }" />
      </div>
      <div class="absolute inset-0 opacity-0 hover:opacity-100" :class="{ '!opacity-100': scrubbing }">
        <slot :pending-value="pendingValue" :position="`${Math.max(0, Math.min(elementX, elementWidth))}px`" />
      </div>
    </div>
  </div>
</template>
