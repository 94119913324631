<script setup lang="ts">
import type { BigFiveAnalytics } from '~/types'

const props = withDefaults(defineProps<{
  items?: BigFiveAnalytics[]
}>(), {
  items: () => [{ label: '', value: 0 }],
})

const labels = computed(() => {
  return props.items.map((item) => {
    return `${item.label} - ${Math.round(item.value || 0)}`
  })
})

const data = computed(() => {
  return props.items.map(item => item.value)
})
</script>

<template>
  <div
    card="~ padded"
    class="flex items-start"
  >
    <GIcon
      name="i-personality"
      class="mr-2 bg-gradient"
      size="3xl"
    />

    <div class="flex grow flex-col overflow-auto">
      <h3>
        BIG 5 Personality
      </h3>
      <p class="text-sm text-muted">
        Based on Big 5 Personality Test.
      </p>

      <div class="flex justify-center">
        <ChartRadar
          :datasets="[{ data }]"
          :labels="labels"
        />
      </div>

      <div class="text-xs text-gray italic">
        Note: The rating is based on how the applicant responds to each question.
      </div>
    </div>
  </div>
</template>
