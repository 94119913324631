import type { EffectScope, Ref } from 'vue'
import type { RoomSettings } from './definition'
import type { Interview } from '~/types'
import { STORAGE_KEY_ROOM_SETTINGS } from '~/constants'

interface UseRoomLocalStorageCache {
  scope: EffectScope
  value: Ref<Record<string, any>>
}

export const currentRoom = computed<Interview | undefined | null>(() => {
  return useRoomStore().room
})

export function useRoomLocalStorage<T extends object>(key: string, initial: () => T): Ref<T> {
  if (import.meta.server)
    return shallowRef(initial())

  // @ts-expect-error bind value to the function
  const map: Map<string, UseRoomLocalStorageCache> = useRoomLocalStorage._ = useRoomLocalStorage._ || new Map()

  if (!map.has(key)) {
    const scope = effectScope(true)
    const value = scope.run(() => {
      const all = useLocalStorage<Record<string, T>>(key, {}, { deep: true })

      return computed(() => {
        const id = currentRoom.value?.uuid
          ? currentRoom.value.uuid
          : '[anonymous]'

        if (!all.value[id])
          all.value[id] = Object.assign(initial(), all.value[id] || {})

        return all.value[id]
      })
    })
    map.set(key, { scope, value: value! })
  }

  return map.get(key)!.value as Ref<T>
}

export function useRoomSettings() {
  const settingsStorage = useRoomLocalStorage<RoomSettings>(STORAGE_KEY_ROOM_SETTINGS, () => getDefaultRoomSettings())
  const preferences = settingsStorage.value.preferences

  // toggle functions
  const toggleHeader = () => preferences.isHeader = !preferences.isHeader
  const toggleSidebarLeft = (value?: boolean) => preferences.isSidebarLeft = value ?? !preferences.isSidebarLeft
  const toggleSidebarRight = (value?: boolean) => preferences.isSidebarRight = value ?? !preferences.isSidebarRight
  const toggleSectionFaqs = (value?: boolean) => {
    preferences.isSidebarLeft = value ?? !preferences.isSidebarLeft // open sidebar
    preferences.isSectionJobDetails = false // close job details
    preferences.isSectionFaqs = value ?? true // open faqs
  }
  const toggleSectionJobDetails = (value?: boolean) => {
    preferences.isSidebarLeft = value ?? !preferences.isSidebarLeft // open sidebar
    preferences.isSectionFaqs = false // close faqs
    preferences.isSectionJobDetails = value ?? true // open job details
  }
  const toggleSectionMediaSettings = (value?: boolean) => {
    preferences.isSidebarRight = value ?? !preferences.isSidebarRight // open sidebar
    preferences.isSectionMediaSettings = value ?? !preferences.isSectionMediaSettings // open media settings
  }

  // TODO: start room
  const startRoom = () => {
    preferences.isRoomStarted = true
    preferences.isOnBoarding = false
  }

  watch(computed(() => preferences.isRoomStarted), (value) => {
    if (value) {
      // force enable media controllers
      preferences.isCamera = true
      preferences.isMicrophone = true
      toggleSectionJobDetails(false)
      toggleSectionMediaSettings(false)
    }
  }, {
    immediate: true,
  })

  return {
    storage: settingsStorage,

    // toggle functions
    toggleHeader,
    toggleSidebarLeft,
    toggleSidebarRight,
    toggleSectionFaqs,
    toggleSectionJobDetails,
    toggleSectionMediaSettings,

    // start room
    startRoom,
  }
}
