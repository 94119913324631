import revive_payload_client_lHGI9dvTDu from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zqMso4N7rl from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UFBUEm7LTT from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_uaKyqGrZuM from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_VcYetrYWnC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oJ6NPF3mR8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_dSYLKLDu1V from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BVBD6ipvx0 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.31.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_ybznQryaWx from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_qiso3zr3eaqmsf5yxonhamsu3e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_biaJZEQhc6 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import theme_client_lOus43AFC9 from "/vercel/path0/node_modules/.pnpm/@una-ui+nuxt-edge@0.33.0-beta.1-28950411.ec8fe5b_@unocss+preset-wind@65.4.2_@unocss+webpack@0_rd2tgzpmn3pz27rgmvul2zlkje/node_modules/@una-ui/nuxt-edge/dist/runtime/plugins/theme.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
export default [
  revive_payload_client_lHGI9dvTDu,
  unhead_zqMso4N7rl,
  router_UFBUEm7LTT,
  payload_client_uaKyqGrZuM,
  navigation_repaint_client_VcYetrYWnC,
  check_outdated_build_client_oJ6NPF3mR8,
  chunk_reload_client_dSYLKLDu1V,
  plugin_vue3_BVBD6ipvx0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ybznQryaWx,
  plugin_client_biaJZEQhc6,
  unocss_MzCDxu9LMj,
  theme_client_lOus43AFC9,
  analytics_client_mtqVw3YRXk
]