<script setup lang="ts">
import type { ColumnDef } from '@tanstack/vue-table'
import type { PlaybackScreeningAnswer } from '~/types'

withDefaults(defineProps<{
  items?: PlaybackScreeningAnswer[]
  loading?: boolean
}>(), {
  loading: false,
})

const columns: ColumnDef<PlaybackScreeningAnswer>[] = [
  {
    header: 'No.',
    accessorFn: (row) => {
      return `${row.screeningQuestion?.sortOrder}.`
    },
    accessorKey: 'sort-order',
  },
  {
    header: 'Question',
    accessorFn: (row) => {
      return row.screeningQuestion?.question
    },
    accessorKey: 'question',
  },
  {
    header: 'Ideal Answer',
    accessorFn: (row) => {
      return row.screeningQuestion?.idealAnswer
    },
    accessorKey: 'ideal',
  },
  {
    header: 'Applicant Answer',
    accessorFn: (row) => {
      return row.answer
    },
    accessorKey: 'answer',
  },
  {
    header: 'Qualified',
    accessorKey: 'isCorrect',
    meta: {
      una: {
        tableHead: 'text-center',
        tableCell: 'text-center',
      },
    },
  },
]
</script>

<template>
  <div v-if="items?.length ?? 0 < 0" class="space-y-4">
    <h1>Screening Questions</h1>
    <GTable
      :columns
      :data="items!"
    >
      <template #question-cell="{ cell: { row: { original } } }">
        <GTooltip
          v-if="original.screeningQuestion?.required"
          :content="`This question is ${original.screeningQuestion?.required ? 'required' : 'optional'} during screening`"
          :_tooltip-content="{
            side: 'bottom',
          }"
        >
          <p class="whitespace-break-spaces">
            {{ original.screeningQuestion?.question }} <span class="text-error">*</span>
          </p>
        </GTooltip>
      </template>

      <template #isCorrect-cell="{ cell: { row: { original } } }">
        <div class="flex items-center justify-center gap-x-2">
          <GTooltip
            :content="original.isCorrect ? 'Passed' : 'Failed'"
            :tooltip="`${original.isCorrect ? 'success' : 'error'}`"
          >
            <GIcon
              size="lg"
              :name="original.isCorrect ? 'i-success' : 'i-close'"
              :class="original.isCorrect ? 'text-success' : 'text-error'"
            />
          </GTooltip>
        </div>
      </template>
    </GTable>
  </div>
</template>
