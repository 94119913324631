
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "una": {
    "primary": "yellow",
    "gray": "stone",
    "radius": 0.5,
    "fontSize": 16
  },
  "env": "preview",
  "buildInfo": {
    "version": "2.0.0-1732003146.21f69d1",
    "time": 1737949482405,
    "commit": "ae77c5d78e26a0907ade966e8eacd3e03c5f5477",
    "shortCommit": "ae77c5d",
    "branch": "main",
    "env": "preview"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
