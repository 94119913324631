<script setup lang="ts">
import { SvgActiveMediaDevice, SvgQuestionAnswer, SvgSaveVideoInterview } from '#components'

const emit = defineEmits(['close'])

const { room } = useRoomStore()

const { countdown, isFinished } = useCountdown({
  duration: 5,
  immediate: true,
})

const listOfSteps: { [key: number]: {
  src?: Component
  title: string
  description: string
} } = {
  0: {
    title: `Welcome to the Interview Room ${`, ${getFirstName(room?.applicantName)}! 📣` || ''}`,
    description: 'Get ready for success! We\'re here to guide you through your pre-interview session and help you prepare. Let\'s jump in and get started.',
  },
  1: {
    src: markRaw(SvgActiveMediaDevice),
    title: 'Active Media Device',
    description: 'Recording automatically starts upon entering the room, please make sure your camera and audio settings are set to active status.',
  },
  2: {
    src: markRaw(SvgQuestionAnswer),
    title: 'Question and Answer',
    description: 'As the AI starts the interview, listen carefully. Pause and gather your thoughts before answering the questions and then speak clearly. No time limit to your answers.',
  },
  3: {
    src: markRaw(SvgSaveVideoInterview),
    title: 'Saving your video interview',
    description: 'Your answers will be recorded as you go along, so please make sure to answer until the end. Leaving the room intentionally will affect your application.',
  },
}

const {
  index,
  steps,
  current,
  isFirst,
  isLast,
  goTo,
  goToNext,
  goToPrevious,
  isCurrent,
} = useStepper(listOfSteps)
</script>

<template>
  <GDialog
    v-bind="$attrs"
    prevent-close
    :show-close="false"
    @update:open="!$event && emit('close')"
  >
    <div class="h-full flex flex-col justify-between overflow-y-auto">
      <!-- header -->
      <div>
        <MiscIntegrationVideo
          v-if="index === 0"
          :src="room?.organization?.organizationVideo || ''"
          class="mx-auto min-h-60 w-full overflow-auto rounded-sm"
        />
        <component
          :is="current.src"
          v-else
          class="mx-auto min-h-60 w-full overflow-auto rounded-sm"
        />
      </div>

      <!-- body -->
      <div class="mt-10 text-center sm:px-10">
        <h3>
          {{ current.title }}
        </h3>

        <p class="mt-2 text-accent">
          {{ current.description }}
        </p>
      </div>

      <!-- actions -->
      <div
        grid="~ cols-[1fr_1fr_1fr]"
        class="mt-16 gap-4"
      >
        <div>
          <GButton
            v-if="!isFirst"
            class="px-3 sm:px-5"
            :disabled="isFirst"
            label="Prev"
            @click="goToPrevious"
          />
        </div>
        <div class="mx-auto flex items-center gap-x-2 transition-colors">
          <GButton
            v-for="(_, i) in steps"
            :key="i"
            :disabled="!isFinished"
            btn="~ icon"
            :class="isCurrent(i) ? 'bg-primary hover:bg-primary-active' : 'bg-$c-gray-200 hover:bg-$c-gray-300'"
            class="p-0"
            square="10px"
            @click="goTo(i)"
          />
        </div>
        <div class="ml-auto">
          <GButton
            class="px-3 sm:px-5"
            btn="solid-primary"
            :disabled="!isFinished"
            :label="!isFinished ? countdown.toString() : isLast ? 'Close' : 'Next'"
            @click="isLast ? emit('close') : goToNext()"
          />
        </div>
      </div>
    </div>
  </GDialog>
</template>
