<script setup lang="ts">
const { getRoom, state } = useRoomStore()
const {
  storage,
  toggleSectionFaqs,
  toggleSectionMediaSettings,
} = useRoomSettings()

const preferences = storage.value.preferences

const sidebarLeftElRef = ref<HTMLElement | null>(null) // element that contains the sidebar
const sidebarRightElRef = ref<HTMLElement | null>(null) // element that contains the sidebar
const ignoreElRef = ref<HTMLElement | null>(null) // element that should be ignored when clicked outside

/**
 * Close sidebar when clicked outside
 *
 * TODO: Remove this when we have a better solution
 */
onClickOutside(sidebarLeftElRef, () => {
  toggleSectionFaqs(false)
}, {
  ignore: [ignoreElRef],
})

onClickOutside(sidebarRightElRef, () => {
  toggleSectionMediaSettings(false)
}, {
  ignore: [ignoreElRef],
})

function onCloseModalOnboarding() {
  preferences.isOnBoarding = false
  toggleSectionFaqs(true)
  toggleSectionMediaSettings(true)
}

await callOnce(async () => {
  const response = await getRoom()
  if (!response?.data?.value) {
    throw createError({
      statusCode: 404,
      message: '😱 Oh no, interview room session not found!',
    })
  }
})
</script>

<template>
  <div
    class="relative min-h-screen flex flex-col"
  >
    <!-- Header -->
    <RoomHeader />

    <!-- Left sidebar -->
    <ClientOnly>
      <div
        v-if="preferences.isSidebarLeft"
        ref="sidebarLeftElRef"
        border="base sm:r"
        class="fixed inset-b-0 inset-t-10 left-0 z-11 w-full overflow-y-auto bg-base px-4 py-12 opacity-100 transition-base sm:w-100 xl:flex sm:px-6 lg:pl-8 xl:pl-6 hover:opacity-100 md:opacity-85"
      >
        <RoomSidebarLeft />
      </div>
    </ClientOnly>

    <!-- Body -->
    <div class="flex grow xl:px-2">
      <!-- Main area -->
      <slot />
    </div>

    <!-- Right sidebar -->
    <ClientOnly>
      <div
        v-if="preferences.isSidebarRight"
        ref="sidebarRightElRef"
        border="base sm:l"
        class="fixed inset-b-0 inset-t-10 right-0 z-11 w-full overflow-y-auto bg-base px-4 py-12 opacity-100 transition-base sm:w-100 xl:flex sm:px-6 lg:pr-8 xl:pr-6 hover:opacity-100 md:opacity-85"
      >
        <RoomSidebarRight />
      </div>
    </ClientOnly>

    <!-- Footer -->
    <ClientOnly>
      <div
        ref="ignoreElRef"
        class="fixed inset-x-0 bottom-0 z-0 z-20 hidden justify-between px-4 pb-4 sm:flex space-x-2"
      >
        <!-- Deskstop -->
        <GButton
          class="hidden text-nowrap leading-1.2rem transition-base md:inline-flex"
          :leading="preferences.isSidebarLeft ? 'i-close' : 'i-question'"
          :disabled="preferences.isRoomStarted"
          :btn="preferences.isSidebarLeft ? 'solid-white' : 'soft-gray'"
          label="Guidelines"
          @click="toggleSectionFaqs()"
        />
        <!-- Mobile -->
        <GButton
          class="md:hidden"
          :label="preferences.isSidebarLeft ? 'i-close' : 'i-question'"
          :disabled="preferences.isRoomStarted"
          icon
          :btn="preferences.isSidebarLeft ? 'solid-white' : 'soft-gray'"
          @click="toggleSectionFaqs()"
        />

        <!-- Desktop -->
        <GButton
          v-if="state === 'pending'"
          class="hidden text-nowrap leading-1.2rem transition-base md:inline-flex"
          :leading="preferences.isSidebarRight ? 'i-close' : 'i-ph-gear-fine-bold'"
          :disabled="preferences.isRoomStarted"
          :btn="preferences.isSidebarRight ? 'solid-white' : 'soft-gray'"
          label="Settings"
          @click="toggleSectionMediaSettings()"
        />

        <!-- Mobile -->
        <GButton
          v-if="state === 'pending'"
          class="md:hidden"
          :label="preferences.isSidebarRight ? 'i-close' : 'i-ph-gear-fine-bold'"
          :disabled="preferences.isRoomStarted"
          icon
          :btn="preferences.isSidebarRight ? 'solid-white' : 'soft-gray'"
          @click="toggleSectionMediaSettings()"
        />
      </div>
    </ClientOnly>

    <!-- Overlays -->
    <RoomModalOnboarding
      v-model:open="preferences.isOnBoarding"
      @close="onCloseModalOnboarding"
    />
  </div>
</template>
