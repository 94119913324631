import type { BehavioralScoreAnalytics, BigFiveAnalytics, DiscProfileAssessmentAnalytics, PlaybackAnalytics, QualificationScoreAnalytics } from '~/types'

export function getQualificationScoreAnalytics(analytics?: PlaybackAnalytics): QualificationScoreAnalytics[] {
  return [
    {
      label: 'Experience Alignment',
      value: analytics?.totalExperienceAlignmentScore || 0,
    },
    {
      label: 'Skill Proficiency',
      value: analytics?.totalSkillProficiencyScore || 0,
    },
    {
      label: 'Industry Knowledge',
      value: analytics?.totalIndustryKnowledgeScore || 0,
    },
    {
      label: 'Adaptability Skills',
      value: analytics?.totalAdaptabilitySkillScore || 0,
    },
    {
      label: 'Leadership Skills',
      value: analytics?.totalLeadershipSkillScore || 0,
    },
    {
      label: 'Innovative Skills',
      value: analytics?.totalInnovativeSkillScore || 0,
    },
  ]
}

export function getBehavioralScoreAnalytics(analytics?: PlaybackAnalytics): BehavioralScoreAnalytics[] {
  return [
    {
      label: 'Language Choice',
      value: analytics?.totalLanguageChoice || 'neutral',
    },
    {
      label: 'Tone',
      value: analytics?.totalTone || 'neutral',
    },
    {
      label: 'Solution Oriented',
      value: analytics?.totalSolutionOriented || 'neutral',
    },
    {
      label: 'Appreciation',
      value: analytics?.totalAppreciation || 'neutral',
    },
  ]
}

export function getDiscProfileAssessmentAnalytics(analytics?: PlaybackAnalytics): DiscProfileAssessmentAnalytics[] {
  return [
    {
      label: 'Dominance',
      value: analytics?.totalDominanceScore || 0,
    },
    {
      label: 'Influence',
      value: analytics?.totalInfluenceScore || 0,
    },
    {
      label: 'Conscientiousness',
      value: analytics?.totalConscientiousnessScore || 0,
    },
    {
      label: 'Steadiness',
      value: analytics?.totalSteadinessScore || 0,
    },
  ]
}

export function getBigFiveAnalytics(analytics?: PlaybackAnalytics): BigFiveAnalytics[] {
  return [
    {
      label: 'Openness',
      value: analytics?.totalBigFiveOpenessScore || 0,
    },
    {
      label: 'Conscientiousness',
      value: analytics?.totalBigFiveConscientiousnessScore || 0,
    },
    {
      label: 'Extraversion',
      value: analytics?.totalBigFiveExtraversionScore || 0,
    },
    {
      label: 'Agreeableness',
      value: analytics?.totalBigFiveAgreeablenessScore || 0,
    },
    {
      label: 'Neuroticism',
      value: analytics?.totalBigFiveNeuroticismScore || 0,
    },
  ]
}
