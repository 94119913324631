<script setup lang="ts">
const props = withDefaults(defineProps<{
  /**
   * Poster image
   */
  poster?: string
  /**
   * Mp4 video source
   */
  src?: string
  /**
   * Enable share button
   */
  isSharable?: boolean
  /**
   * Enable options button
   */
  isOptions?: boolean
}>(), {
  isSharable: false,
  isOptions: false,
})

const emit = defineEmits(['share'])

const video = ref<HTMLVideoElement | null>(null)
const container = ref<HTMLElement | null>(null)
const { focused } = useFocusWithin(container)
const { audioOutputs } = useDevicesList()
const { toggle, isFullscreen } = useFullscreen(container)
const { playing, currentTime, duration, muted, volume, buffered, togglePictureInPicture, isPictureInPicture, rate, waiting } = useMediaControlSettings(video, {
  src: props.src,
})

onKeyStroke(' ', (e) => {
  // if command or control key is pressed, don't toggle fullscreen
  if (e.metaKey || e.ctrlKey)
    return

  if (!focused.value) {
    e.preventDefault()
    playing.value = !playing.value
  }
})

onKeyStroke(['f', 'F'], (e) => {
  // if command or control key is pressed, don't toggle fullscreen
  if (e.metaKey || e.ctrlKey)
    return

  e.preventDefault()
  toggle()
})

onKeyStroke(['i', 'I'], (e) => {
  // if command or control key is pressed, don't toggle fullscreen
  if (e.metaKey || e.ctrlKey)
    return

  e.preventDefault()
  togglePictureInPicture()
})

onKeyStroke(['w', 'W'], (e) => {
  // if command or control key is pressed, don't toggle fullscreen
  if (e.metaKey || e.ctrlKey)
    return

  e.preventDefault()
  if (volume.value + 0.10 <= 1)
    volume.value += 0.10
  else
    volume.value = 1
})

onKeyStroke(['s', 'S'], (e) => {
  // if command or control key is pressed, don't toggle fullscreen
  if (e.metaKey || e.ctrlKey)
    return

  e.preventDefault()
  if (volume.value - 0.10 >= 0)
    volume.value -= 0.10
  else
    volume.value = 0
})

onKeyStroke(['d', 'D'], (e) => {
  // if command or control key is pressed, don't toggle fullscreen
  if (e.metaKey || e.ctrlKey)
    return

  e.preventDefault()
  if (currentTime.value + 5 <= duration.value)
    currentTime.value += 5
  else
    currentTime.value = duration.value
})

onKeyStroke(['a', 'A'], (e) => {
  // if command or control key is pressed, don't toggle fullscreen
  if (e.metaKey || e.ctrlKey)
    return

  e.preventDefault()
  if (currentTime.value - 5 >= 0)
    currentTime.value -= 5
  else
    currentTime.value = 0
})

const volumeIcon = computed(() => {
  if (audioOutputs.value.length < 1)
    return 'i-ph-speaker-slash-fill'
  else if (muted.value)
    return 'i-ph-speaker-x-fill'
  else if (volume.value === 0)
    return 'i-ph-speaker-none-fill'
  else if (volume.value <= 0.5)
    return 'i-ph-speaker-low-fill'
  else
    return 'i-ph-speaker-high-fill'
})

watch(volume, () => {
  muted.value = false
})

function formatDuration(seconds: number) {
  return new Date(1000 * seconds).toISOString().slice(14, 19)
}

function speedRate() {
  if (rate.value < 2.0)
    return rate.value += 0.25
}

function slowRate() {
  if (rate.value > 0.25)
    return rate.value -= 0.25
}

const endBuffer = computed(() => buffered.value.length > 0 ? buffered.value[buffered.value.length - 1][1] : 0)

defineExpose({
  playing,
  currentTime,
  duration,
  waiting,
  setPlaying: (value: boolean, timeline?: number) => {
    playing.value = value

    if (timeline)
      currentTime.value = timeline

    // force currentTime to 0 if timeline is 0 (init fix for video not playing when currentTime is 0)
    if (timeline === 0)
      currentTime.value = 0
  },
  // Add more properties here if needed
})
</script>

<template>
  <div
    id="video-container"
    ref="container"
    border="~ base"
    class="group relative max-h-screen max-w-initial w-100% flex items-center justify-center overflow-hidden rounded-md"
  >
    <ClientOnly>
      <div id="video-controls-container-top" :class="`${!playing ? 'opacity-100' : 'opacity-0'} absolute top-0 left-0 right-0 z-1 transition-opacity duration-150 ease-in-out group-focus-within:opacity-100 group-hover:opacity-100`">
        <div class="pointer-events-none absolute top-0 aspect-[30/4] w-full from-black bg-gradient-to-b opacity-60 -z-1" />
        <div id="controls" class="w-full flex items-center gap-x-2 p-1">
          <GButton
            class="py-3"
            :label="volumeIcon"
            icon
            :class="muted || audioOutputs.length <= 0 ? 'text-error' : 'text-white'"
            btn="~"
            square
            @click="muted = !muted"
          />
          <CommonScrubber v-model="volume" :max="1" :is-muted="muted" class="ml-2 w-32" />

          <div class="flex grow justify-end">
            <GButton v-if="isSharable" label="i-ph-share-network-fill" icon btn="~" square class="p-2 text-white" @click="emit('share')" />
            <GButton :label="!isPictureInPicture ? 'i-ph-picture-in-picture' : 'i-ph-picture-in-picture-fill'" icon btn="~" square class="p-2 text-white" @click="togglePictureInPicture" />
            <GButton :label="!isFullscreen ? 'i-ph-corners-out' : 'i-ph-corners-in'" icon btn="~" square class="p-2 text-white" @click="toggle" />
            <GButton v-if="isOptions" label="i-ph-dots-three-vertical-bold" icon btn="~" square class="p-2 text-white" />
          </div>
        </div>
      </div>
      <div
        v-if="!playing"
        square="3em sm:5em"
        class="absolute flex items-center justify-center rounded-full bg-white"
      >
        <GIcon
          v-if="waiting"
          size="1.5em sm:3em"
          name="i-loading"
          class="absolute text-primary-500"
        />
        <SvgAppIcon
          v-else
          square="1.5em sm:3em"
        />
      </div>
      <div
        id="video-controls-container-bottom"
        :class="`${!playing ? 'opacity-100' : 'opacity-0'} absolute bottom-0 left-0 right-0 z-1 transition-opacity duration-150 ease-in-out group-focus-within:opacity-100 group-hover:opacity-100`"
      >
        <div class="pointer-events-none absolute bottom-0 aspect-[30/4] w-full from-black bg-gradient-to-t opacity-60 -z-1" />
        <div id="timeline-container" class="group/timeline m-inline-2 h-7px flex cursor-pointer items-center">
          <CommonScrubber v-model="currentTime" :max="duration" :secondary="endBuffer" class="w-full">
            <template #default="{ position, pendingValue }">
              <div
                class="absolute bottom-0 mb-4 transform rounded bg-black px-2 py-1 text-xs text-white -translate-x-1/2"
                :style="{ left: position }"
              >
                {{ formatDuration(pendingValue) }}
              </div>
            </template>
          </CommonScrubber>
        </div>
        <div id="controls" class="flex items-center px-4 py-2">
          <div class="flex">
            <GButton label="i-ph-arrow-counter-clockwise" icon btn="~" square class="text-white" @click="currentTime -= 5" />
            <GButton :label="!playing ? 'i-ph-play-fill' : 'i-ph-pause-fill'" icon class="text-white" btn="~" square @click="playing = !playing" />
            <GButton label="i-ph-arrow-clockwise" icon btn="~" square class="text-white" @click="currentTime += 5" />
          </div>
          <div class="flex items-center">
            <GButton label="i-ph-caret-left" icon btn="~" square class="text-white" @click="slowRate()" />
            <span class="text-white">
              {{ rate }}x
            </span>
            <GButton label="i-ph-caret-right" icon btn="~" square class="text-white" @click="speedRate()" />
          </div>
          <div class="flex grow justify-end">
            <span class="text-sm text-white">
              {{ `${formatDuration(currentTime)} / ${formatDuration(duration)}` }}
            </span>
          </div>
        </div>
      </div>
    </ClientOnly>

    <video
      ref="video"
      class="aspect-video w-full object-cover"
      :poster="poster"
      @click="playing = !playing"
      @dblclick="toggle"
    />
  </div>
</template>
