<script setup lang="ts">
import type { MediaVideoPlayer } from '#build/components'
import type { TranscriptionItem } from '~/types'

const props = defineProps<{
  /**
   * Name of Applicant
   *
   * eg: Gabrielle Espina
   */
  applicantName?: string
  /**
   * List of items in the transcript
   *
   * @see to `TranscriptionItem` type for more details
   */
  items: TranscriptionItem[]
  /**
   * Video player instance
   *
   */
  videoPlayer: InstanceType<typeof MediaVideoPlayer> | null
}>()

const isInsightModalVisible = ref(false)
const currentIndex = ref<number>(0)

// we need to add 1 to the current time to avoid duplication of the current time in accordion
const isCurrentTime = computed(() => (props.videoPlayer?.currentTime || 0) + 1)

function isCurrentTimeWithinRange(item: TranscriptionItem) {
  return isWithinRange(isCurrentTime.value, item?.playback?.timeStart, item?.playback?.timeEnd)
}

function onViewInsight(i: number) {
  isInsightModalVisible.value = true
  currentIndex.value = i
}
</script>

<template>
  <div>
    <h3 class="mb-4">
      Playback Transcript
    </h3>
    <ClientOnly>
      <GAccordion
        :items="items"
        unstyle
        multiple
        :default-open="false"
        :una="{
          accordionItem: 'group',
        }"
      >
        <template #label="{ item, open }">
          <GButton
            :btn="isCurrentTimeWithinRange(item) ? 'solid-gray' : 'solid-white'"
            :class="{
              'rounded-b-none border-b-0': open,
            }"
            class="h-auto w-full whitespace-normal py-4"
          >
            <template #default>
              <div class="mx-0.5 w-full flex gap-x-4">
                <div
                  class="square-9"
                  :class="{
                    'animate-bounce': isCurrentTimeWithinRange(item) && videoPlayer?.playing && !videoPlayer?.waiting,
                  }"
                >
                  <SvgAIIcon class="square-9" />
                </div>

                <div class="w-full flex flex-col items-baseline gap-3 text-start">
                  <div class="w-full flex items-baseline justify-between space-x-2">
                    <p class="text-sm text-base">
                      {{ item.label }}
                    </p>

                    <TranscriptionBadge
                      :quality="item.answerQuality"
                      size="11px"
                      class="hidden sm:flex"
                    />
                  </div>

                  <div class="w-full flex items-center justify-between gap-1">
                    <div class="flex items-center space-x-2">
                      <GButton
                        label="AI Insights"
                        leading="i-ph-cpu-bold"
                        size="xs"
                        btn="solid-gray"
                        @click.stop.prevent="onViewInsight(item.index)"
                      />

                      <div border="~ 0.3 base" class="h-6" />

                      <GButton
                        :label="isCurrentTimeWithinRange(item) && videoPlayer?.playing
                          ? convertSecondToMinute(videoPlayer?.currentTime!!)
                          : convertSecondToMinute(item?.playback?.timeStart)"
                        :leading="
                          isCurrentTimeWithinRange(item) && videoPlayer?.playing
                            ? 'i-ph-stop-circle-bold'
                            : 'i-ph-play-circle-bold'
                        "
                        size="xs"
                        class="px-0 transition-colors duration-500"
                        :loading="isCurrentTimeWithinRange(item) && videoPlayer?.waiting"
                        :btn="isCurrentTimeWithinRange(item)
                          ? 'text-error'
                          : 'text-gray'"
                        @click="(event:any) => {
                          if (videoPlayer?.playing && isCurrentTimeWithinRange(item) && videoPlayer?.playing)
                            videoPlayer?.setPlaying(false)
                          else
                            videoPlayer?.setPlaying(true, item.playback.timeStart)

                          // if open, don't close the accordion
                          if (open)
                            event.stopPropagation()
                        }"
                      />
                    </div>

                    <div class="flex items-center justify-between space-x-2">
                      <GButton
                        label="i-ph-caret-left-bold"
                        icon
                        size="xs"
                        btn="~"
                        square
                        class="ms-auto transform text-$c-gray-800 transition-transform duration-200"
                        :class="[open && 'rotate-90 text-primary']"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </GButton>
        </template>

        <template #content="{ item, open }">
          <div
            p="3"
            flex="~ col"
            :class="{
              'border border-base border-t-0 rounded-b': open,
            }"
          >
            <div class="flex gap-x-4">
              <div class="flex flex-col items-center justify-between">
                <GAvatar
                  avatar="solid-primary"
                  :label="getInitials(applicantName)"
                  cursor="pointer"
                  size="sm"
                />
              </div>
              <div class="flex flex-col gap-y-2">
                <div class="flex justify-between">
                  <span
                    class="btn p-0"
                  >
                    {{ applicantName }}
                  </span>
                  <TranscriptionBadge
                    :quality="item.answerQuality"
                    size="0.65rem"
                    class="flex sm:hidden"
                  />
                </div>

                <p
                  class="max-h-60 overflow-y-auto text-left text-sm lg:text-justify text-muted"
                >
                  {{ item.content }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </GAccordion>

      <template #fallback>
        <div class="animate-pulse">
          <div v-for="i in 3" :key="i" class="mb-6 flex flex-col gap-y-4">
            <div class="h-12 w-full rounded-md bg-muted" />
            <div class="flex items-center gap-x-4 rounded-md">
              <div class="square-12 rounded-full bg-muted" />
              <div class="h-8 w-full rounded-md bg-muted" />
            </div>
            <div class="h-4 w-full rounded-md bg-muted" />
            <div class="h-4 w-full rounded-md bg-muted" />
            <div class="h-4 w-full rounded-md bg-muted" />
          </div>
        </div>
      </template>

      <!-- Overlay -->
      <TranscriptionModalInsight
        v-model="isInsightModalVisible"
        :item="items[currentIndex]"
        :is-last="(items[currentIndex].index ?? 0) + 1 >= items.length ? true : false"
        :is-first="items[currentIndex].index === 0 ? true : false"
        @close="isInsightModalVisible = false"
        @next="currentIndex++"
        @prev="currentIndex--"
      />
    </ClientOnly>
  </div>
</template>
