<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const props = withDefaults(defineProps<{
  /**
   * Title for linear progress
   * @example Application Progress | Remaining Job Posts | Interview Score
   */
  label: string
  /**
   * Value for linear progress
   * @example 1 | 10 | 36 | 78 | 100
   * @default 0
   */
  value?: number
  /**
   * Max limit for linear progress
   * @example 3 | 100 |1000
   * @default 100
   */
  maxValue?: number
  /**
   * Color attribute for linear progress
   * @example primary | success | red
   */
  color?: string
}>(), { value: 0, maxValue: 100 })

const percentage = computed(() => Math.round((props.value / props.maxValue) * 100))

const variant = computed(() => {
  if (props.color) {
    return {
      progress: 'primary',
      text: 'text-primary',
    }
  }

  if (percentage.value >= 80) {
    return {
      progress: 'success',
      text: 'text-success',
    }
  }
  else if (percentage.value >= 60) {
    return {
      progress: 'warning',
      text: 'text-warning',
    }
  }
  else {
    return {
      progress: 'error',
      text: 'text-error',
    }
  }
})
</script>

<template>
  <div>
    <div class="flex items-center justify-between">
      <p class="mb-1 text-sm text-muted">
        {{ label }}
      </p>
      <p class="text-sm text-muted">
        <span :class="twMerge('font-semibold', variant.text)">{{ Math.round(value) }}</span>
        / {{ maxValue }}
      </p>
    </div>

    <GProgress
      :model-value="value"
      :progress="variant.progress"
      :max-value
    />
  </div>
</template>
