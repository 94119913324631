<template>
  <!-- Desktop -->
  <div class="hidden items-center justify-end sm:flex space-x-2">
    <div class="flex flex-col items-end md:mr-1 md:-space-y-1.5">
      <span class="hidden text-0.7em md:block text-muted">Powered by</span>
      <AppLogo size="md:0.7em" />
    </div>

    <GSeparator orientation="vertical" class="h-7" />

    <SettingsColorMode />
    <GThemeSwitcher />
  </div>

  <!-- Mobile -->
  <GButton
    class="block md:hidden"

    btn="text-gray hover:text-primary"
    label="i-ph-dots-three-outline-fill"
    icon square
  />
</template>
