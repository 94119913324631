<script setup lang="ts">
const props = withDefaults(defineProps<{
  labels: string[]
  /**
   * Pie chart legends
   *
   * @example ['dominance', 'steadiness', 'Conscientiousness', 'Influence']
   */
  datasets: (number | undefined)[]
  /**
   * Disc Value
   *
   * @example data:[39, 10, 26, 25]
   */
  color?: string[]
  /**
   * Collection of Color
   *
   * @example color:['red','blue','green', 'orange']
   */
  borderWidth?: number
  /**
   * Border thickness
   *
   * @default 0
   */
}>(), { borderWidth: 0 })

const primaryDISC = computed(() => {
  const disc = [
    {
      name: props.labels[0],
      value: props.datasets[0],
    },
    {
      name: props.labels[1],
      value: props.datasets[1],
    },
    {
      name: props.labels[2],
      value: props.datasets[2],
    },
    {
      name: props.labels[3],
      value: props.datasets[3],
    },
  ]

  const highest = disc.reduce((prev, current) => (prev.value ?? 0) > (current.value ?? 0) ? prev : current)

  return highest
})
</script>

<template>
  <!-- TODO: CLEAN UP -->
  <div class="grid grid-cols-2 gap-3">
    <div class="relative h-0 w-full overflow-clip bg-transparent pb-[100%]">
      <div :class="primaryDISC.name === props.labels[0] ? 'bg-error !text-white -bottom-[74%] -right-[74%]' : 'bg-error/50 dark:bg-error dark:bg-opacity-30 text-gray-800 -bottom-[80%] -right-[80%]'" class="absolute h-[160%] w-[160%] rounded-full">
        <div
          :class="primaryDISC.name === props.labels[0] ? 'text-white w-[48%] bottom-[51%]' : 'text-error w-[45%] bottom-[55%]'"
          class="absolute break-words text-right"
        >
          <div class="text-[20px] font-semibold">
            {{ props.datasets[0]?.toFixed(0) ?? 2 }}%
          </div>
          <span class="text-7px md:block sm:text-sm">
            Dominance
          </span>
        </div>
      </div>
    </div>

    <div class="relative h-0 w-full overflow-clip bg-transparent pb-[100%]">
      <div
        :class="primaryDISC.name === props.labels[3]
          ? 'bg-warning !text-white -bottom-[74%] -left-[74%]'
          : 'bg-warning/30 dark:bg-warning dark:bg-opacity-30 text-gray-800 -bottom-[80%] -left-[80%]'
        "
        class="absolute h-[160%] w-[160%] rounded-full"
      >
        <div
          :class="primaryDISC.name === props.labels[3] ? 'text-white w-[48%] bottom-[51%]' : 'text-warning w-[45%] bottom-[55%]'"
          class="absolute right-0 break-words text-left"
        >
          <div class="text-[20px] font-semibold">
            {{ props.datasets[3]?.toFixed(0) ?? 2 }}%
          </div>
          <span class="text-7px md:block sm:text-sm">
            Influence
          </span>
        </div>
      </div>
    </div>

    <div class="relative h-0 w-full overflow-clip bg-transparent pb-[100%]">
      <div
        :class="primaryDISC.name === props.labels[2]
          ? 'bg-info !text-white -top-[74%] -right-[74%]'
          : 'bg-info/30 dark:bg-info dark:bg-opacity-30 text-gray-800 -top-[80%] -right-[80%]'
        "
        class="absolute h-[160%] w-[160%] rounded-full bg-[#D0DFFD]"
      >
        <div
          :class="[
            primaryDISC.name === props.labels[2] ? 'text-white w-[50%] top-[51%]' : 'text-info w-[45%] top-[55%]',
          ]"
          class="absolute break-words text-right"
        >
          <div class="text-[20px] font-semibold">
            {{ props.datasets[2]?.toFixed(0) ?? 2 }}%
          </div>
          <span class="text-7px md:block sm:text-sm">
            Conscientiousness
          </span>
        </div>
      </div>
    </div>

    <div class="relative h-0 w-full overflow-clip bg-transparent pb-[100%]">
      <div
        :class="primaryDISC.name === props.labels[1]
          ? 'bg-success !text-white -top-[74%] -left-74%'
          : 'bg-success/30 dark:bg-success dark:bg-opacity-30 text-gray-800 -top-80% -left-80%'
        "
        class="absolute h-[160%] w-160% rounded-full"
      >
        <div
          :class="primaryDISC.name === props.labels[1] ? 'text-white w-50% top-[51%]' : 'text-success w-[45%] top-[55%]'"
          class="absolute right-0 break-words text-left"
        >
          <div class="text-[20px] font-semibold">
            {{ props.datasets[1]?.toFixed(0) ?? 2 }}%
          </div>
          <span class="text-7px md:block sm:text-sm">
            Steadiness
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
