import type { UseFetchOptions } from 'nuxt/app'
import { useRequestHeaders } from 'nuxt/app'

/**
 * We use this to fetch data from our API instead of using default fetch,
 * This fetch is made specifically for our Laravel API, in which we don't need to worry about CSRF tokens.
 */

export function useFetchApi<T>(path: string, options: UseFetchOptions<T> = {}) {
  let headers: any = {
    accept: 'application/json',
  }
  const token = useCookie('XSRF-TOKEN')

  if (token.value)
    headers['X-XSRF-TOKEN'] = token.value as string

  if (import.meta.server) {
    headers = {
      ...headers,
      ...useRequestHeaders(['cookie']),
    }
  }

  path = path.startsWith('/') ? `/server/api${path}` : path

  return useFetch(path, {
    credentials: 'include',
    watch: false,
    ...options,
    headers: {
      ...headers,
      ...options?.headers,
    },
  })
}

export function $fetchApi<T>(path: string, options: UseFetchOptions<T> = {}) {
  return useFetchApi(path, options)
}

export async function useFetchCsrf() {
  return await useFetch('/sanctum/csrf-cookie')
}
