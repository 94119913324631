<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js'
import { Chart as ChartJS, Filler, Legend, LineElement, PointElement, RadialLinearScale, Tooltip } from 'chart.js'
import { Radar } from 'vue-chartjs'

const props = withDefaults(defineProps<{
  labels: string[]
  /**
   * Pie chart legends
   *
   * @example ['dominance', 'steadiness', 'Conscientiousness', 'Influence']
   */
  datasets: {
    data: (number | undefined)[]
  }[]
  /**
   * Collection of data
   *
   * @example
   * [
   * {data:[39, 10, 26, 25]},
   * {data:[25, 25, 25, 25]}
   * ]
   */
  min?: number
  /**
   * Suggested lowest value
   */
  max?: number
  /**
   * Suggested highest value
   */
}>(), { min: 0, max: 10 })

const colorMode = useColorMode()

const settings = useUnaSettings().settings
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

const chartData = computed<ChartData<'radar'>>(() => {
  const collection: any[] = []

  props.datasets.forEach((item) => {
    collection.push({
      data: item.data,
      borderColor: `rgb(${settings.value.primaryColors['--una-primary-500']})`,
      backgroundColor: `rgba(${settings.value.primaryColors['--una-primary-500']}, .60)`,
      borderWidth: 0,
      pointRadius: 0,
      pointBackgroundColor: `rgb(${settings.value.primaryColors['--una-primary-500']})`,
      pointHoverBackgroundColor: `rgb(${settings.value.primaryColors['--una-primary-500']})`,
      pointHoverBorderColor: `rgb(${settings.value.primaryColors['--una-primary-500']})`,
      pointBorderColor: `rgb(${settings.value.primaryColors['--una-primary-500']})`,
    })
  })

  return {
    labels: props.labels,
    datasets: collection,
  }
})

const chartOptions = computed<ChartOptions<'radar'>>(() => {
  return {
    responsive: true,
    maintainAspectRatio: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        backgroundColor: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-950'] : settings.value.grayColors['--una-gray-50']})`,
        labelColor: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-400'] : settings.value.grayColors['--una-gray-700']})`,
        titleColor: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-400'] : settings.value.grayColors['--una-gray-500']})`,
        titleMarginBottom: 10,
        bodyColor: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-500'] : settings.value.grayColors['--una-gray-400']})`,
        borderColor: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-200'] : settings.value.grayColors['--una-gray-800']})`,
        borderWidth: 1,
        bodySpacing: 10,
        boxWidth: 6,
        boxHeight: 6,
        boxPadding: 6,
        callbacks: {
          title(tooltipItems) {
            return tooltipItems[0].label.split('-')[0].trim()
          },
        },
      },
    },
    scales: {
      r: {
        grid: {
          color: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-600'] : settings.value.grayColors['--una-gray-500']})`,
        },
        angleLines: {
          display: false, // Hide the angle lines by default, add this to props if needed
          color: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-500'] : settings.value.grayColors['--una-gray-400']})`,
          borderDash: [5, 4],
        },
        ticks: {
          display: false,
          beginAtZero: true,
          stepSize: 1,
        },
        pointLabels: {
          font: {
            size: 12,
          },
          color: `rgb(${colorMode?.preference === 'light' ? settings.value.grayColors['--una-gray-700'] : settings.value.grayColors['--una-gray-200']})`,
        },
        suggestedMin: props.min,
        suggestedMax: props.max,
      },
    },
  }
})
</script>

<template>
  <Radar :data="chartData" :options="chartOptions" />
</template>
