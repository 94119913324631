<script setup lang="ts">
import type { BandScoreAnalytics } from '~/types'

const props = withDefaults(defineProps<{
  items?: Partial<BandScoreAnalytics>
}>(), {
})
const isBandModalVisible = ref(false)

const isPrequalified = computed(() => {
  return props.items?.isPrequalified || (props.items?.overallScore ?? 0) >= (props.items?.minimumScore ?? 0)
})
</script>

<template>
  <div
    card="~ padded"
    class="flex items-start"
  >
    <GIcon
      name="i-ielts"
      class="mr-2 bg-gradient"
      size="3xl"
    />

    <div class="flex grow flex-col">
      <div class="flex flex-col gap-x-2 gap-y-4 md:flex-row md:items-center">
        <div class="flex grow flex-col">
          <h3>
            IELTS Verbal Assessment
          </h3>
          <div class="flex flex-wrap">
            <p class="mr-1 text-sm text-muted">
              Overall speaking proficiency score.
            </p>

            <GButton
              size="xs"
              class="p-0 text-nowrap underline underline-offset-3 -mt-1"
              btn="link-info"
              label="Learn more"
              @click="isBandModalVisible = true"
            />
          </div>
        </div>
        <div>
          <GBadge
            :badge="(items?.overallScore ?? 0) >= (items?.minimumScore ?? 0) ? 'solid-success' : 'solid-error'"
            size="sm"
            class="text-nowrap"
            :label="isPrequalified ? 'Pre-Qualified' : 'Failed'"
          />
        </div>
      </div>
      <div class="my-4 flex space-x-8">
        <div class="flex flex-col space-y-1">
          <p class="text-sm text-muted">
            Minimum Band Score
          </p>
          <div flex>
            <h4>
              Band {{ items?.minimumScore }}
              <!-- <GIcon name="i-ph-info" size="sm" class="mb-2 text-muted" /> -->
            </h4>
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <p class="text-sm text-muted">
            Overall Band Score
          </p>
          <h4 :class="(items?.overallScore ?? 0) >= (items?.minimumScore ?? 0) ? 'text-success' : 'text-error'">
            Band {{ items?.overallScore }}
            <!-- <GIcon name="i-ph-info" size="sm" class="mb-2 text-muted" /> -->
          </h4>
        </div>
      </div>

      <GAlert
        alert="border-info" class="py-2"
        :una="{
          alertDescription: 'leading-5',
        }"
        title="Description" :description="items?.description"
      />
    </div>

    <!-- modal -->
    <LazyPlaybackModalBandDescription v-model="isBandModalVisible" @close="isBandModalVisible = false" />
  </div>
</template>
