<script setup lang="ts">
import type { BehavioralScoreAnalytics } from '~/types'

const props = withDefaults(defineProps<{
  items?: BehavioralScoreAnalytics[]
}>(), {
})

const data = computed(() => {
  return props.items?.map(item => (
    {
      label: item.label,
      value: item.value?.toLocaleLowerCase(),
    }
  ))
})
</script>

<template>
  <div
    card="~ padded"
    class="flex items-start"
  >
    <GIcon
      name="i-behavior"
      class="mr-2 bg-gradient"
      size="3xl"
    />

    <div class="flex grow flex-col">
      <h3>
        Behavioral Scoring
      </h3>
      <p class="text-sm text-muted">
        How candidates behave and respond to different situations
      </p>

      <div class="my-6 flex flex-col divide-y divide-base">
        <div v-for="({ label, value }, i) in data" :key="i" class="grid grid-cols-1 items-center gap-x-3 py-2 md:grid-cols-2">
          <span class="col-span-1 text-center text-sm md:text-left text-muted">
            {{ label }}
          </span>
          <div class="col-span-1 flex items-center justify-center py-4 space-x-8">
            <div class="flex flex-col items-center">
              <GIcon name="i-ph-smiley-sad-duotone" size="3xl" :class="value === 'negative' ? 'text-error' : 'text-muted'" />
              <span :class="`text-xs ${value === 'negative' ? 'text-error' : 'text-muted'}`">Negative</span>
            </div>
            <div class="flex flex-col items-center">
              <GIcon name="i-ph-smiley-meh-duotone" size="3xl" :class="`text-xs ${value === 'neutral' ? 'text-warning' : 'text-muted'}`" />
              <span :class="`text-xs ${value === 'neutral' ? 'text-warning' : 'text-muted'}`">Neutral</span>
            </div>
            <div class="flex flex-col items-center">
              <GIcon name="i-ph-smiley-duotone" size="3xl" :class="`text-xs ${value === 'positive' ? 'text-success' : 'text-muted'}`" />
              <span :class="`text-xs ${value === 'positive' ? 'text-success' : 'text-muted'}`">Positive</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
