<script setup lang="ts">
import type { TranscriptionItem } from '~/types'

const props = defineProps<{
  quality: Pick<TranscriptionItem, 'answerQuality'>['answerQuality']
}>()

const type = computed(() => {
  switch (props.quality) {
    case 'excellent':
      return {
        badge: 'soft-success',
        label: 'EXCELLENT',
      }
    case 'good':
      return {
        badge: 'soft-info',
        label: 'GOOD',
      }
    case 'poor':
      return {
        badge: 'soft-error',
        label: 'POOR',
      }
    default:
      return {
        badge: 'soft-gray',
        label: 'N/A',
      }
  }
})
</script>

<template>
  <GBadge
    v-bind="type"
  />
</template>
