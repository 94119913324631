<template>
  <svg width="300" height="300" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3448_1106)">
      <path d="M16.21 31.2092C16.21 31.2092 6.54221 25.7383 3.02488 18.1135C-0.492449 10.4887 3.23735 4.66059 7.43319 3.0014C11.629 1.34221 20.1057 0.551443 25.6641 3.37058C31.2224 6.18971 31.8941 12.9371 29.4364 17.5604C26.9786 22.1837 21.2119 25.2316 19.053 26.0642C18.1266 26.4505 17.1794 26.775 16.2169 27.0357L16.21 31.2092Z" class="fill-$c-gray-100" />
      <path d="M16.2803 18.0078V27.5165L20.1666 25.4525L16.2803 18.0078Z" class="fill-$c-gray-300" />
      <path d="M16.2883 32.0031C12.6797 30.4722 9.35545 28.2405 6.47658 25.4168C2.18204 20.9878 0.612336 17.8378 0.102419 13.482C-0.172342 11.7429 -0.039896 9.95668 0.487271 8.28827C1.01444 6.61985 1.91953 5.12365 3.11832 3.93826C6.48761 0.479364 11.3016 -0.111139 16.2281 0.0174106C21.1545 0.145961 25.0199 0.105672 28.7401 3.93826C29.8145 5.08293 30.6584 6.45887 31.2182 7.9788C31.778 9.49873 32.0414 11.1297 31.9916 12.7667C31.8058 14.879 31.2012 16.9221 30.2199 18.7546C29.2387 20.587 27.9042 22.1644 26.3085 23.379C23.2663 25.9918 19.8884 28.1032 16.2829 29.6442V27.221C16.2829 27.221 30.141 21.6335 30.1396 12.1507C30.1283 10.752 29.8387 9.37187 29.2906 8.10726C28.7425 6.84265 27.949 5.72316 26.9651 4.82578C24.0249 2.25029 20.8516 2.24697 16.2281 2.09899C11.8513 2.15729 7.93781 2.45804 5.16205 4.95131C4.17972 5.83813 3.39636 6.95928 2.87202 8.22696C2.34767 9.49464 2.09618 10.8746 2.13678 12.2646C2.16694 15.9028 4.19278 19.7269 7.88145 23.5804C10.3786 26.0734 13.2123 28.1332 16.2829 29.688L16.2883 32.0031Z" fill="url(#paint0_linear_3448_1106)" />
      <path d="M21.3261 6.26465H10.8618C7.88816 6.26465 5.47754 8.89305 5.47754 12.1354C5.47754 15.3777 7.88816 18.0061 10.8618 18.0061H21.3261C24.2997 18.0061 26.7104 15.3777 26.7104 12.1354C26.7104 8.89305 24.2997 6.26465 21.3261 6.26465Z" class="fill-$c-gray-200" />
      <path d="M21.1611 7.85352H11.2602C9.07996 7.85352 7.3125 9.7809 7.3125 12.1584C7.3125 14.536 9.07996 16.4634 11.2602 16.4634H21.1611C23.3414 16.4634 25.1089 14.536 25.1089 12.1584C25.1089 9.7809 23.3414 7.85352 21.1611 7.85352Z" class="fill-$c-gray-700" />
      <path d="M12.6611 14.3285C13.777 14.3285 14.6816 13.342 14.6816 12.1252C14.6816 10.9083 13.777 9.92188 12.6611 9.92188C11.5452 9.92188 10.6406 10.9083 10.6406 12.1252C10.6406 13.342 11.5452 14.3285 12.6611 14.3285Z" style="fill: rgb(var(--una-primary-600))" />
      <path d="M19.8974 14.3285C21.0133 14.3285 21.9179 13.342 21.9179 12.1252C21.9179 10.9083 21.0133 9.92188 19.8974 9.92188C18.7816 9.92188 17.877 10.9083 17.877 12.1252C17.877 13.342 18.7816 14.3285 19.8974 14.3285Z" style="fill: rgb(var(--una-primary-600))" />
      <path d="M5.5924 11.8203H2.12305V12.3614H5.5924V11.8203Z" class="fill-$c-gray-200" />
      <path d="M30.1286 11.8203H26.4111V12.3614H30.1286V11.8203Z" class="fill-$c-gray-200" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_3448_1106" x1="15.9802" y1="-1.2146" x2="15.9802" y2="32.0031" gradientUnits="userSpaceOnUse">
        <!-- pink-700 -->
        <stop style="stop-color: rgb(236 72 153);;" />
        <!-- pink-800 -->
        <stop offset="0.284" style="stop-color: rgb(219 39 119);" />
        <stop offset="1" style="stop-color: rgb(var(--una-primary-700));" />
      </linearGradient>
      <clipPath id="clip0_3448_1106">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
