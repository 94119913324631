<script lang="ts">
enum CategoryValue {
  Feedback = 'feedback',
  IELTS = 'ielts',
  Qualifications = 'qualifications',
  Behavioral = 'behavioral',
  DISC = 'disc',
  BigFive = 'bigFive',
}
</script>
<script setup lang="ts">
import type { TranscriptionItem } from '~/types'
import {
  RadioGroup,
  RadioGroupOption,
} from '@headlessui/vue'

withDefaults(defineProps<{
  item: TranscriptionItem
}
>(), {
})

interface CategoryItem {
  value: string
  label: string
  icon: string
}



const category: CategoryItem[] = [
  {
    value: CategoryValue.Feedback,
    label: 'Feedback',
    icon: 'i-feedback',
  },
  {
    value: CategoryValue.IELTS,
    label: 'IELTS',
    icon: 'i-ielts',
  },
  {
    value: CategoryValue.Qualifications,
    label: 'Qualifications',
    icon: 'i-qualification',
  },
  {
    value: CategoryValue.Behavioral,
    label: 'Behavioral',
    icon: 'i-behavior',
  },
  {
    value: CategoryValue.DISC,
    label: 'DISC Profile',
    icon: 'i-disc',
  },
  {
    value: CategoryValue.BigFive,
    label: 'Big 5',
    icon: 'i-personality',
  },
]

const selectedCategory = ref<CategoryValue>(CategoryValue.Feedback)
</script>

<template>
  <div class="flex flex-col space-y-4">
    <div class="space-y-2">
      <div class="flex items-start gap-x-2">
        <div class="w-full flex flex-col justify-center">
          <span class="mb-1 text-sm text-primary font-semibold">Question {{ ((item.index ?? 0) + 1).toString() }} of {{ item.totalQuestions }}</span>
          <div
            card
            class="p-2"
          >
            <p class="text-sm">
              {{ item.label }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div class="w-full flex items-end justify-between">
          <span class="mb-1 text-sm text-primary font-semibold">Candidate Answer</span>
          <TranscriptionBadge
            class="my-2"
            :quality="item.answerQuality"
            size="0.65rem"
          />
        </div>
        <div
          card
          class="max-h-60 overflow-y-auto p-2"
        >
          <p class="text-sm">
            {{ item.content }}
          </p>
        </div>
      </div>
    </div>

    <div border="t base" />

    <div class="grid grid-cols-1 gap-y-2 lg:grid-cols-5 lg:gap-x-4 lg:gap-y-0">
      <div class="col-span-1 overflow-x-auto py-2 sm:py-0">
        <RadioGroup v-model="selectedCategory" class="flex flex-row flex-nowrap justify-around gap-2 lg:flex-col lg:justify-center">
          <RadioGroupOption
            v-for="{ value, label, icon } in category"
            :key="value" v-slot="{ checked }"
            as="div"
            :value="value"
          >
            <!-- desktop -->
            <GButton
              :label="label"
              :leading="icon"
              class="hidden w-full justify-start lg:flex"
              :btn="checked ? 'solid-primary' : 'ghost-gray'"
            />

            <!-- mobile -->
            <GButton
              class="lg:hidden"
              size="lg"
              :label="icon"
              icon
              :btn="checked ? 'solid-primary' : 'solid-gray'"
            />
            <div />
          </RadioGroupOption>
        </RadioGroup>
      </div>

      <div class="col-span-1 lg:col-span-4">
        <PlaybackFeedback v-if="selectedCategory === 'feedback'" v-bind="item?.feedback" />
        <PlaybackVerbalAssessment v-else-if="selectedCategory === 'ielts'" :items="item?.ielts" />
        <PlaybackQualificationScoreItems v-else-if="selectedCategory === 'qualifications'" :items="item?.qualification" />
        <PlaybackBehavioralScoreItems v-else-if="selectedCategory === 'behavioral'" :items="item?.behavioral" />
        <PlaybackDiscProfileAssessment v-else-if="selectedCategory === 'disc'" :items="item?.disc" />
        <PlaybackBigFivePersonality v-else-if="selectedCategory === 'bigFive'" :items="item?.bigFive" />
      </div>
    </div>
  </div>
</template>
