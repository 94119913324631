<script setup lang="ts">
import type { TranscriptionItem } from '~/types'

withDefaults(defineProps<{
  item: TranscriptionItem
  isFirst?: boolean
  isLast?: boolean
}
>(), {
})

defineEmits(['close', 'next', 'prev'])

const modelValue = defineModel<boolean>()
</script>

<template>
  <CommonModal
    v-model="modelValue"
    max-w="sm:4xl"
    h="sm:5xl"
    :header="{
      title: 'Transcription Insights',
      description: 'Insights and analysis of the transcription for a specific question.',
      icon: 'i-ph-cpu',
    }"
    @close="$emit('close')"
  >
    <TranscriptionInsightItem
      :item="item"
    />

    <template #footer>
      <div class="flex items-center justify-between">
        <GButton
          :disabled="isFirst"
          leading="i-ph-caret-left-bold"
          label="Previous"
          btn="soft-primary"
          @click="$emit('prev')"
        />
        <GButton
          :disabled="isLast"
          trailing="i-ph-caret-right-bold"
          label="Next"
          btn="soft-primary"
          @click="$emit('next')"
        />
      </div>
    </template>
  </CommonModal>
</template>
