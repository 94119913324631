import type { InterviewAnswer, TranscriptionItem } from '~/types'

export function getTranscriptionItems(interviewAnswers?: InterviewAnswer[], config?: {
  minimumBandScore: number | undefined
}): TranscriptionItem[] {
  const items: TranscriptionItem[] = []
  let timeStart = 0

  if (!interviewAnswers)
    return items

  interviewAnswers.forEach((answer: InterviewAnswer) => {
    items.push({
      index: interviewAnswers.findIndex(item => item.interviewAnswerId === answer.interviewAnswerId),
      label: answer.question,
      content: answer.answer,
      answerQuality: answer.answerQuality,
      totalQuestions: interviewAnswers.length,
      playback: {
        timeStart,
        timeEnd: timeStart + (answer.durationInSeconds || 0),
      },
      feedback: { excelledAt: answer.excelledAt, improveAt: answer.improveAt },
      qualification: [
        { label: 'Experience Alignment', value: answer.experienceAlignmentScore },
        { label: 'Skill Proficiency', value: answer.skillProficiencyScore },
        { label: 'Industry Knowledge', value: answer.industryKnowledgeScore },
        { label: 'Adaptability Skills', value: answer.adaptabilitySkillScore },
        { label: 'Leadership Skills', value: answer.leadershipSkillScore },
        { label: 'Innovative Skills', value: answer.innovativeSkillScore },
      ],
      behavioral: [
        { label: 'Language Choice', value: answer.languageChoice },
        { label: 'Tone', value: answer.tone },
        { label: 'Solution Oriented', value: answer.solutionOriented },
        { label: 'Appreciation', value: answer.appreciation },
      ],
      ielts: {
        overallScore: answer.bandScore,
        minimumScore: config?.minimumBandScore,
        description: answer.bandScoreDescription,
      },
      disc: [
        {
          label: 'Dominance',
          value: answer.dominanceScore || 0,
        },
        {
          label: 'Influence',
          value: answer.influenceScore || 0,
        },
        {
          label: 'Conscientiousness',
          value: answer.conscientiousnessScore || 0,
        },
        {
          label: 'Steadiness',
          value: answer.steadinessScore || 0,
        },
      ],
      bigFive: [
        {
          label: 'Openness',
          value: answer.bigFiveOpenessScore || 0,
        },
        {
          label: 'Conscientiousness',
          value: answer.bigFiveConscientiousnessScore || 0,
        },
        {
          label: 'Extraversion',
          value: answer.bigFiveExtraversionScore || 0,
        },
        {
          label: 'Agreeableness',
          value: answer.bigFiveAgreeablenessScore || 0,
        },
        {
          label: 'Neuroticism',
          value: answer.bigFiveNeuroticismScore || 0,
        },
      ],
    })

    timeStart += answer.durationInSeconds || 0
  })

  return items
}

/**
 * @warning Use this as fallback only, overall score should be calculated from the backend.
 */
export function getTranscriptionOverallScore(interviewAnswers?: InterviewAnswer[]) {
  if (!interviewAnswers)
    return 0

  const scores = interviewAnswers.map((answer) => {
    return (answer.score + answer.experienceAlignmentScore + answer.industryKnowledgeScore + answer.skillProficiencyScore + answer.adaptabilitySkillScore + answer.leadershipSkillScore + answer.innovativeSkillScore) / 7
  })

  const sum = scores.reduce((a, b) => a + b, 0)

  return Math.round(sum / scores.length)
}
