<script setup lang="ts">
import type { DiscProfileAssessmentAnalytics } from '~/types'

const props = withDefaults(defineProps<{
  items?: DiscProfileAssessmentAnalytics[]
}>(), {
  items: () => [{ label: '', value: 0 }],
})

const labels = computed(() => {
  return props.items.map(item => item.label)
})

const data = computed(() => {
  return props.items.map(item => item.value)
})
</script>

<template>
  <div
    card="~ padded"
    class="flex items-start"
  >
    <GIcon
      name="i-disc"
      class="mr-2 bg-gradient"
      size="3xl"
    />

    <div class="flex grow flex-col">
      <h3>
        DISC Profile Assessment
      </h3>
      <p class="text-sm text-muted">
        DISC® is a personal assessment tool.
      </p>

      <div class="my-6 flex flex-col">
        <PlaybackChartDisc
          :labels
          :datasets="data"
        />
      </div>
      <p class="text-xs text-gray italic">
        Note: The rating is based on how the applicant responds to each question. They didn't take the actual DISC profile assessment.
      </p>
    </div>
  </div>
</template>
